import { lazy } from 'react';

import { Navigate, Outlet, Route } from 'react-router';

import { PERMISSIONS, PermissionGate } from 'modules/permission';
import MainLayout from 'routes/components/layout';
import Loadable from 'routes/components/Loadable';
import AuthGuard from 'routes/components/route-guard/AuthGuard';
import { withOutletForModal } from 'utils/withOutletForModal';

// pages routing
const OrderDetailPage = Loadable(lazy(() => import('pages/orders/detail')));
const OrdersHistoryPage = Loadable(lazy(() => import('pages/orders/history')));
const OrdersTodayPage = Loadable(lazy(() => import('pages/orders/today')));
const OrdersReviewsPage = Loadable(lazy(() => import('pages/orders/reviews')));
const OrdersStatusBoard = Loadable(lazy(() => import('pages/orders/statusBoard')));
const OrderAddressPage = Loadable(lazy(() => import('pages/orders/editAddress')));
const DeliveriesHistoryPage = Loadable(lazy(() => import('pages/fleet/deliveries/history')));
const DeliveriesActivePage = Loadable(lazy(() => import('pages/fleet/deliveries/active')));
const DeliveriesDetailsModal = Loadable(lazy(() => import('pages/fleet/deliveries/detail')));
const DriversPage = Loadable(lazy(() => import('pages/fleet/drivers')));
const DriverDetailsPage = Loadable(lazy(() => import('pages/fleet/drivers/details')));
const AreasPage = Loadable(lazy(() => import('pages/warehouses/areas')));
const ProductsPage = Loadable(lazy(() => import('pages/contents-v2/products')));
const PickersPage = Loadable(lazy(() => import('pages/warehouses/pickers')));
const CustomersPage = Loadable(lazy(() => import('pages/customers')));
const CustomerDetailsModal = Loadable(lazy(() => import('pages/customers/details')));
const CustomerAddressEditModal = Loadable(lazy(() => import('pages/customers/addresses/edit')));
const BannersPage = Loadable(lazy(() => import('pages/marketing/banners')));
const CategoriesPage = Loadable(lazy(() => import('pages/contents/categories')));
const CategoriesV2Page = Loadable(lazy(() => import('pages/contents-v2/categories')));
const CategoryEditPage = Loadable(lazy(() => import('pages/contents/categories/edit')));
const CategoryEditV2Page = Loadable(lazy(() => import('pages/contents-v2/category-edit')));
const CategorySortPage = Loadable(lazy(() => import('pages/contents/categories/CategorySort')));
const WarehousesPage = Loadable(lazy(() => import('pages/warehouses/warehouses')));
const DiscountsPage = Loadable(lazy(() => import('pages/marketing/discounts')));
const CompensationsPage = Loadable(lazy(() => import('pages/marketing/compensations')));
const SegmentsPage = Loadable(lazy(() => import('pages/marketing/segments')));
const CollectionsPage = Loadable(lazy(() => import('pages/contents/collections')));
const EmployeesPage = Loadable(lazy(() => import('pages/admin/employees')));
const SyncPage = Loadable(lazy(() => import('pages/settings/sync')));
const BatchingPage = Loadable(lazy(() => import('pages/fleet/deliveries/batching')));
const DeliverySettingsPage = Loadable(lazy(() => import('pages/settings/delivery')));
const LargeOrderEtaSettingsPage = Loadable(lazy(() => import('pages/settings/largeOrderEta')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const BatchingSandboxPage = Loadable(lazy(() => import('pages/tools/sandbox/batching-sandbox')));
const WarehouseLabelPage = Loadable(lazy(() => import('pages/tools/operations/warehouseLabel')));
const PaymentSettings = Loadable(lazy(() => import('pages/settings/payments')));
const DiscountsSettingsPage = Loadable(lazy(() => import('pages/settings/discounts')));
const DeliverooSettingsPage = Loadable(lazy(() => import('pages/settings/deliveroo')));
const CashReportPage = Loadable(lazy(() => import('pages/reports/cashReport')));
const LoyaltyPage = Loadable(lazy(() => import('pages/marketing/loyalty')));
const OffersPage = Loadable(lazy(() => import('pages/marketing/offers')));
const SubCategoriesPage = Loadable(lazy(() => import('pages/contents-v2/subcategories')));
const SubCategoryEditPage = Loadable(lazy(() => import('pages/contents-v2/subcategory-edit')));

const MainRoutes = (
  <Route path="/" element={<AuthGuard children={<Outlet />} />}>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Navigate to="orders/today" />} />

      <Route path="orders" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.ORDERS.READ} />}>
        <Route index element={<Navigate to="today" />} />
        <Route path="today" element={withOutletForModal(OrdersTodayPage)}>
          <Route path="order/:orderId" element={<OrderDetailPage />} />
        </Route>
        <Route path="history" element={withOutletForModal(OrdersHistoryPage)}>
          <Route path="order/:orderId" element={<OrderDetailPage />} />
        </Route>
        <Route path="status-board" element={withOutletForModal(OrdersStatusBoard)}>
          <Route path="order/:orderId" element={<OrderDetailPage />} />
        </Route>
        <Route path="edit-address" element={<OrderAddressPage />} />
      </Route>

      <Route path="customers" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.CUSTOMERS.READ} />}>
        <Route index element={<Navigate to="list" />} />
        <Route path="list" element={withOutletForModal(CustomersPage)}>
          <Route path=":id" element={withOutletForModal(CustomerDetailsModal)}>
            <Route path="order/:orderId" element={<OrderDetailPage />} />
            <Route path="addresses/:addressId">
              <Route index element={<Navigate to="edit" />} />
              <Route path="edit" element={<CustomerAddressEditModal />} />
            </Route>
          </Route>
        </Route>
        <Route path="reviews" element={withOutletForModal(OrdersReviewsPage)}>
          <Route path="order/:orderId" element={<OrderDetailPage />} />
        </Route>
      </Route>

      <Route path="fleet">
        <Route index element={<Navigate to="deliveries/active" />} />
        <Route path="deliveries" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.DELIVERIES.READ} />}>
          <Route index element={<Navigate to="today" />} />
          <Route path="active" element={withOutletForModal(DeliveriesActivePage)}>
            <Route path=":id" element={<DeliveriesDetailsModal />} />
            <Route path="order/:orderId" element={<OrderDetailPage />} />
          </Route>

          <Route path="history" element={withOutletForModal(DeliveriesHistoryPage)}>
            <Route path=":id" element={<DeliveriesDetailsModal />} />
            <Route path="order/:orderId" element={<OrderDetailPage />} />
          </Route>

          <Route path="batching" element={<BatchingPage />} />
        </Route>

        <Route path="drivers" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.DRIVERS.READ} />}>
          <Route index element={<DriversPage />} />
          <Route path=":id" element={withOutletForModal(DriverDetailsPage)}>
            <Route path="order/:orderId" element={<OrderDetailPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="marketing">
        <Route index element={<Navigate to="discounts" />} />
        <Route path="banners" element={<PermissionGate showError children={<BannersPage />} permission={PERMISSIONS.BANNERS.READ} />} />
        <Route
          path="discounts"
          element={<PermissionGate showError children={<DiscountsPage />} permission={PERMISSIONS.DISCOUNTS.READ} />}
        />
        <Route path="segments" element={<SegmentsPage />} />
        <Route
          path="compensations"
          element={<PermissionGate showError children={withOutletForModal(CompensationsPage)} permission={PERMISSIONS.DISCOUNTS.READ} />}
        >
          <Route path="order/:orderId" element={<OrderDetailPage />} />
        </Route>
        <Route path="loyalty" element={<PermissionGate children={<LoyaltyPage />} permission={PERMISSIONS.DISCOUNTS.READ} />} />
        <Route path="offers" element={<PermissionGate children={<OffersPage />} permission={PERMISSIONS.OFFERS.READ} />} />
      </Route>

      <Route path="warehouses">
        <Route index element={<WarehousesPage />} />
        <Route path="areas" element={<PermissionGate showError children={<AreasPage />} permission={PERMISSIONS.AREAS.READ} />} />
        <Route path="pickers" element={<PermissionGate showError children={<PickersPage />} permission={PERMISSIONS.PICKERS.READ} />} />
      </Route>

      <Route path="contents-v2">
        <Route index element={<Navigate to="categories" />} />
        <Route path="categories" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.CATEGORIES.READ} />}>
          <Route index element={<CategoriesV2Page />} />
          <Route path=":categoryId" element={<CategoryEditV2Page />} />
        </Route>
        <Route path="products" element={<PermissionGate showError children={<ProductsPage />} permission={PERMISSIONS.PRODUCTS.READ} />} />
        <Route path="subcategories" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.CATEGORIES.READ} />}>
          <Route index element={<SubCategoriesPage />} />
          <Route path=":subcategoryId" element={<SubCategoryEditPage />} />
        </Route>
      </Route>

      <Route path="contents">
        <Route index element={<Navigate to="categories" />} />
        <Route path="categories" element={<PermissionGate showError children={<Outlet />} permission={PERMISSIONS.CATEGORIES.READ} />}>
          <Route index element={<CategoriesPage />} />
          <Route path=":categoryId">
            <Route index element={<Navigate to="edit" />} />
            <Route path="edit" element={<CategoryEditPage />} />
          </Route>
          <Route path="sort" element={<CategorySortPage />} />
        </Route>
        <Route
          path="collections"
          element={<PermissionGate showError children={<CollectionsPage />} permission={PERMISSIONS.COLLECTIONS.READ} />}
        />
      </Route>

      <Route path="tools">
        <Route index element={<Navigate to="sandbox" />} />
        <Route path="sandbox">
          <Route index element={<Navigate to="batching" />} />
          <Route path="batching" element={<BatchingSandboxPage />} />
        </Route>
        <Route path="operations">
          <Route index element={<Navigate to="warehouseLabel" />} />
          <Route path="warehouseLabel" element={<WarehouseLabelPage />} />
        </Route>
      </Route>

      <Route path="admin">
        <Route index element={<Navigate to="employees" />} />
        <Route
          path="employees"
          element={<PermissionGate showError children={<EmployeesPage />} permission={PERMISSIONS.EMPLOYEES.READ} />}
        />
        <Route path="settings">
          <Route index element={<Navigate to="batching" />} />
          <Route path="shopify-sync" element={<PermissionGate showError children={<SyncPage />} permission={PERMISSIONS.SHOPIFY.SYNC} />} />
          <Route
            path="delivery"
            element={<PermissionGate showError children={<DeliverySettingsPage />} permission={PERMISSIONS.DELIVERY_SETTINGS.MANAGE} />}
          />
          <Route
            path="payments"
            element={<PermissionGate showError children={<PaymentSettings />} permission={PERMISSIONS.PAYMENTS_SETTINGS.MANAGE} />}
          />
          <Route
            path="largeOrderEta"
            element={
              <PermissionGate showError children={<LargeOrderEtaSettingsPage />} permission={PERMISSIONS.LARGE_ORDER_ETA_SETTINGS.MANAGE} />
            }
          />
          <Route
            path="deliveroo"
            element={<PermissionGate showError children={<DeliverooSettingsPage />} permission={PERMISSIONS.WAREHOUSES.MANAGE} />}
          />
          <Route
            path="discountsSettings"
            element={<PermissionGate showError children={<DiscountsSettingsPage />} permission={PERMISSIONS.DISCOUNTS_SETTINGS.MANAGE} />}
          />
        </Route>
      </Route>

      <Route path="reports">
        <Route index element={<Navigate to="cashReport" />} />
        <Route
          path="cashReport"
          element={<PermissionGate showError children={<CashReportPage />} permission={PERMISSIONS.REPORTS.READ} />}
        />
      </Route>
    </Route>
    <Route path="*" element={<MaintenanceError />} />
  </Route>
);

export default MainRoutes;
